* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Style the accordion section. This is the main div */
.accordion__section {
  display: flex;
  flex-direction: column;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
}

/* Add a background color to the bottom if it is clicked on ( add the active class with JS) */
.accordion:hover,
.active {
  /* background-color: #ccc; */
}

/* Style the accordion content title */
.accordion__title {
  font-weight: 600;
  font-size: 1rem;
  text-align: left;

  color: #434343;
color:#317E6B
}

.spacer {
  width: 10px;
  height: 10px;
}

/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
  min-width: .6rem;
}

.rotate {
  transform: rotate(90deg);
}

/* Style the accordion content panel. Note: Hidden by default */
.accordion__content {
  background-color: #fff;
  overflow: hidden;
  transition: max-height 0.6s ease;
  color: #444;
  /* color: #317E6B; */
  /* max-height: 0; */
}

.accordion__text {
  font-weight: 400;

  font-size: 1rem;
  padding: 18px;
}
