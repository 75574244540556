/* .NavBar-area {
  height: 80px;
  width: 100%;
  background-color: #44a38b;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -10;
} */

/* .faq-container {
  background: url("../../assets/images/web_help_5.jpg") fixed;
  background-size: cover;
  background-position: center;
  padding: 3% 15%;
  color: #fff;
} */

/* .title-area {
  padding-bottom: 2%;
}

.bottom-area {
  padding-top: 5%;
} */

/* h1 {
  font-size: 2rem;
  font-weight: 600;
} */

/* .faq-board{
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius:  5px;
  padding: 10px;
} */

/* .faq-message {
  font-size: 1.6rem;
  padding-top: 1vh;
  max-width: 600px;
} */

/* .bright-yellow-text {
  color: #eddd64;
  font-style: normal;
  font-weight: 700;
} */

/* .bright-yellow-text:hover {
  color: #fff;
  font-style: normal;
} */

.bright-green-text {
  color: #44a38b;
  font-style: normal;

}

.bright-green-text:hover {
  color: #eddd64;
  font-style: normal;
}

@media screen and (max-width: 960px) {
  .faq-container {
    padding: 3% 5%;
  }

  h1 {
    font-size: 1.5rem;
  }

  .faq-message {
    font-size: 1.3rem;
    padding-top: 1vh;
  }

  .break {
    display: none;
  }
}
