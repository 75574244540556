* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  /* font-size: 16px; */
  line-height: 1.38;
}

.home,
.ayuda,
.contacto,
.sign-up {
  /* display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem; */
}

.ayuda {
  /* background-image: url('/images/img-2.jpg'); */
  /* background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #000;
  font-size: 100px; */
}

.contacto {
  /* background-image: url('/images/img-1.jpg'); */
  /* background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #000;
  font-size: 100px; */
}

.sign-up {
  background-image: url('/images/img-8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}