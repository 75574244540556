.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  min-width: 100vw;
  padding-top: 160px;
  background-color: #3b9a83;
  margin-top: -80px;
}

.styleCircularProgress {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 80vh;
  width: 100vw;
}

.NavBar-area {
  height: 80px;
  width: 100%;
  background-color: #44a38b;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -10;
}