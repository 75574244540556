/* IMPORTS */
/* @import url(''); */

/* NAVBAR CONTAINER SECTION */
/* DIV */
.NavBar-container {
  background-color: #3B9A83;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 10%;
  z-index: -1;
  /* box-shadow: rgba(0, 0, 0, 0.5) 0px 3px 30px; */
}

/* NAVBAR SOLID GREEN OPTION */
.solid-green {
  width: 100%;
  /* height: 80px;
  background-color: #44a38b; */
}

/* LOGO SECTION */
/* DIV */
.logo-container {
  cursor: pointer;
}

/* MENU SECTION */
/* DIV */
.menu-container {
}

/* UL */
.nav-menu {
  list-style: none;
}

/* LI */
.nav-li {
  display: inline-block;
  padding: 0px 0 0 8px;
  transition: all 0.3s ease 0s;
}

/* LINK */
.link-item {
  color: #fff;
  text-decoration: none;
  /* font-size: 1.2rem; */
  font-size: 19px;
  padding: 0.5rem 1rem;
}

.link-item:hover {
  background-color: #009b84;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

/* HREF */
.link-button {
  background-color: #009b84;
  color: #fff;
  text-decoration: none;
  /* font-size: 1.2rem; */
  font-size: 24px;
  font-weight: 700;
  padding: 0.5rem 2rem;
  border-radius: 30px;
}

.link-button:hover {
  background-color: #00b89c;
  color: #fff;
  transition: all 0.2s ease-out;
}

.one-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.button-container2 {
  min-width: 235px;
  margin-top: 20px;
}

/* HAMBURGER MENU SECTION */
/* DIV */
.menu-icon {
  display: none;
  color: #fff;
}

/* CHANGE OF SCREEN SIZE */
@media screen and (max-width: 1040px) {
  .NavBar-container {
    justify-content: center;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 550px;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    box-shadow: inset 0 0 0 1000px rgba(0, 164, 140, 0.98);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    height: 300px;
  }

  .link-item {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .link-item:hover {
    background: linear-gradient(90deg, #44a38b 0%, #19c69a 50%, #44a38b 100%);
    border-radius: 0;
  }

  .link-button {
    text-align: center;
    /* padding: 1rem; */
    width: 100%;
    align-self: center;
    display: table;
    font-size: 1rem;
    margin-bottom: 40px;
  }

  .one-row {
    display: block;
    flex-direction: row;
    justify-content: space-between;
  }

  .logo-container {
    position: relative;
    margin-left: 0;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}
