.contact-container {
  background: #19c69a;
  width: 100vw;
  height: 100vh;
  padding: 5% 10%;
  background: url("../../assets/images/web_contact_3.jpg") fixed;
  /* background-size: 100% 100%; */
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  padding-top: 100px;
}

.NavBar-area {
  height: 80px;
  width: 100%;
  background-color: #44a38b;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -10;
}

.red-border {
  border: 2px solid #DA7B7B !important;
}

.textarea-autosize{
  padding: 0;
  margin: 0;
}

input:focus {
  outline:none !important;
}

select:focus {
  outline:none !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.blank-div{
  height: 20px;
  width: 100%;
  /* background-color: pink; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
  align-items: center;
}

.alertSpan{
  color: #DA7B7B;
  margin-left: 1rem;
}

.text-after-checkbox {
  margin-left: 0.5rem;
  color: #4f4f4f;
}

.spacer {
  margin-bottom: 30px;
}

.top-contact {
  text-align: center;
  padding-top: 20px;
}

.contact-title {
  font-size: 50px;
  /* font-size: 1.5rem; */
  font-weight: 700; /* bold */
  color: #ffffff;
  text-align: left;
  font-family: Montserrat;
}

.center-contact {
  background-color: #fff;
  justify-content: center;
  display: flex;
}

.bottom-contact {
  width: 100%;
  height: 50px;
  background-color: white;
}

.button-container {
  background-color: #44a38b;
  width: 150px;
  height: 40px;
}

.button-text {
  text-align: center;
  color: #fff;
}

.name-email {
  display: inline;
}

.input-boxes {
  display: inline-block;
}

/* //////////////////////// */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: rgb(248, 246, 253);
}

body,
input,
textarea,
button {
  /* font-family: "Inter", sans-serif; */
}

.app {
  width: 400px;
  margin: 0 auto;
  /* height: 100vh;*/
}

/* Contact.js */
.form {
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding: 300px; */
  padding: 80px 10%;
  /* padding: 10% 10%; */
}

.form > h1 {
  margin-bottom: 30px;
  font-size: 90px;
  font-weight: 700;
}

.form > input,
textarea {
  /* padding: 20px; */
  border-radius: 5px;
  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.137); */
  /* margin-bottom: 20px; */
  border: 1px solid lightgray;
  /* border: none; */
  background: #fff;
  font-size: 16px;
  color: rgb(0, 0, 32);
  outline: none;
}

.form > input:focus,
textarea:focus {
  /* border: 1px solid rgb(0, 0, 196); */
}

.form > label {
  padding-bottom: 10px;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
}

.send-button {
  width: 100%;
  padding: 15px;
  /* margin: 20px 200px; */
  border: none;
  background-color: #e0e0e0;
  font-weight: 600;
  font-size: 1rem;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  margin-top: 20px;
}

.green-submit-button {
  background-color: #3B9A83;
}

.send-button:hover {
  /* background-color: #19c69a; */
  /* color: #fff; */
}

.div-modal-button {
  text-align: right;
}

.button-modal {
  padding: 15px;
  /* margin: 20px 200px; */
  border: none;
  background-color: #fff;
  font-weight: 600;
  font-size: 1.4rem;
  border-radius: 5px;
  color: #44a38b;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  margin-top: 10px;
  text-align: right;
}

.button-modal:hover {
  /* background-color: #19C69A; */
  color: #317e6b;
}

.form-container {
  width: 100%;
  background-color: #fff;
  border-radius: 20px;
  padding: 2.5% 5%;
}

.dropbtn {
  background-color: #fff;
  color: #44a38b;
  padding: 16px;
  font-size: 18px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  border-radius: 20px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  align-self: center;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 25px;
}

/* Links inside the dropdown */
.dropdown-content text {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #44a38b;
  color: #fff;
}

.dropdown2:hover {
  /* background-color: #44a38b; */
  /* color: #fff; */
}

.dropdown2 {
  background-color: #000;
  /* color: #44a38b; */
  font-size: 18px;
  font-weight: 600;
  padding: 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 30px;
  font-size: 16px;
  color: rgb(0, 0, 32);
  border: none;
  background-color: rgba(255, 255, 255, 0);
  border-bottom: 5px solid white;
  border-radius: 0;
  color: white;
  appearance: none;
  font-size: 1.2rem;
  font-weight: 600;
  padding-left: 20px;
}

.select-box {
  font-size: 1rem;
  font-weight: 600; /* semi bold */
  padding: 1rem;
  cursor: pointer;
  border: 2px solid #828282;
  border-radius: 10px;
  color: #4f4f4f;
  appearance: none;
  width: 100%;
  margin-top: 20px; /*Spacing between inputs*/
  /* margin-bottom: 20px; Spacing between inputs */
}

.text-box {
  font-size: 1rem;
  font-weight: 600; /* semi bold */
  padding: 1rem;
  cursor: pointer;
  border: 2px solid #828282;
  border-radius: 10px;
  color: #4f4f4f;
  width: 100%;
  /* margin-top: 20px; */
}

.single-row {
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
}

.left-mail {
  float: left;
  width: 50%;
  align-items: center;
  justify-content: center;
  /* text-align: center; */
  padding-right: 1vw;
}

.right-mail {
  float: left;
  width: 50%;
  align-items: center;
  justify-content: center;
  /* text-align: center; */
  /* margin: 0 */
  /* width: 300px; */
  padding-left: 1vw;
}

.input-rectangle {
  /* padding: 20px;
      border-radius: 5px;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.137);
      margin-bottom: 20px;
      border: 1px solid lightgray;
      border: none;
      background: #fff;
      font-size: 16px;
      color: rgb(0, 0, 32);
      outline: none; */
  padding: 20px;
  /* border-radius: 5px; */
  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.137); */
  margin-bottom: 20px;
  /* border: 1px solid lightgray; */
  border: none;
  /* background: #fff; */
  font-size: 16px;
  color: rgb(0, 0, 32);
  outline: none;
  width: 100%;
  border-bottom: 5px solid white;
  background-color: rgba(255, 255, 255, 0);
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
}
.input-rectangle::placeholder {
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
}

.row-label {
  color: #fff;
  font-weight: bold;
  font-size: 20px;
}

.form > textarea {
  border: none;
  background-color: rgba(255, 255, 255, 0);
  border-bottom: 5px solid white;
  border-radius: 0;
  color: white;
  font-size: 1.2rem;
  /* height: 200px; */
  resize: both;
  /* font-weight: 900; */
}

.form > textarea::placeholder {
  color: white;
  font-weight: 600;
}

.accept {
  color: white;
  padding: 20px 0 20px 10px;
}

.privacy-link {
  color: #3B9A83;
}

.privacy-link:hover {
  color: #6EB89D;
}

@media screen and (max-width: 960px) {
  .contact-title {
    font-size: 1.5rem;
    text-align: center;
  }

  .left-mail {
    width: 100%;
    padding-right: 0vw;
  }

  .right-mail {
    width: 100%;
    padding-left: 0vw;
    padding-bottom: 20px;
  }

  .accept {
    text-align: center;
  }

  .contact-container {
    padding: 3% 2%;
  }

  .form {
    padding: 40px 5%;
  }
}
