.Footer-container {
  /* background: url(/images/people_5.png) center center no-repeat; */
  /* background: url(/images/people_5.png); */
  /* background: url(../../assets/images/web_people_6.jpg); */
  width: '100%';
  background-color: #3B9A83;
  background-position: center;
  background-size: cover;
  z-index: -1;
}

.Footer-filter {
  /* background-color: rgba(0, 164, 140, 0.4); */
  /* background: linear-gradient(
    180deg,
    rgba(68, 163, 139, 1) 0%,
    rgba(39, 226, 186, 0.5) 100%
  ); */
  height: 100%;
  width: 100%;
  z-index: 1;
}

.Footer-top {
  position: relative;
  z-index: 2;
  text-align: center;
  padding: 40px 0 40px 0;
}

.social-logo {
  width: 50px;
  margin: 5px 10px;
  /* filter: brightness(80%); */
  border-radius: 25px;
}

.Footer-bottom {
  z-index: 2;
  position: relative;
  /* padding-top: 40px; */
  padding-bottom: 40px;
  text-align: center;
  color: #fff;
}

.copyright {
  font-size: 14px;
  padding-top: 10px;
  /* padding-bottom: 40px; */
  color: #fff;
}

.text-row {
  cursor: pointer;
  list-style: none;
}

.link-item {
  color: #fff;
  text-decoration: none;
  /* font-size: 1.2rem; */
  font-size: 19px;
  padding: 0.5rem 1rem;
}

.link-item:hover {
  background-color: #009b84;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.single-row-logo {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  /* margin-right: -5px; */
  /* margin-left: -5px; */
  box-sizing: border-box;
  width: 100%;
  /* background-color: pink; */
  flex-direction: row;
  justify-content: flex-end;
  padding-bottom: 40px;
}

.left-logo-logo {
  float: left;
  width: 50%;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-right: 1vw;
  min-width: 210px;
}

.right-logo {
  float: left;
  width: 50%;
  /* align-items: center;  */
  /* justify-content: center; */
  text-align: left;
  margin: 0;
  min-width: 210px;
  /* width: 300px; */
  /* padding-left: 1vw; */
}

@media screen and (max-width: 420px) {
  .single-row-logo {
    justify-content: center;
  }
}
