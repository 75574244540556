/* MAIN DIV */
.home__section {
}

/* TOP SECTION */
.top__section {
  width: 100%;
  background: url("../../assets/images/people_group_1.jpg");
  background-size: cover;
}

/* MIDDLE SECTION */
.middle__section {
  width: 100%;
  background-color: #fff;
}

/* BOTTOM SECTION */
.bottom__section {
  width: 100%;
  background-color: #fff;
  /* display: flex; */
  /* flex-direction: row; */
  justify-content: center;
  padding: 50px;
}


/* NAVBAR */
.NavBar__section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

/* TEXT AND CTA */
.cover__section {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 80px;
}

/* .text__section {
  width: 50%;
  padding: 6vw 0 0 10%;
} */

.phone__section {
  width: 50%;
  padding: 5.5vw 0 0 0;
  display: flex;

  justify-content: center;
}

.text__section {
  padding: 5% 0 5% 10%;
  width: 50%;
}

.big__title,
.big__subtitle {
  color: #fff;
  text-align: flex-start;
}

.big__title {
  font-size: 4rem;
  padding: 60px 0 60px;
}

.big__subtitle {
  font-size: 3rem;
}

.cta__section {
  padding-bottom: 20px;
}

.cta__section__bottom {
  text-align: center;
  color: #009b84;
  font-size: 2rem;
}

.color__change {
  color: #eddd64;
}

.icons__section {
  /* max-width: 340px; */
  display: flex;
  flex-direction: row;
  /* justify-content: flex-start; */
  justify-content: flex-start;
  padding: 30px 0;
}

.icons__section__bottom {
  /* max-width: 340px; */
  display: flex;
  flex-direction: row;
  /* justify-content: flex-start; */
  justify-content: center;
  padding: 30px 0;
}

.icon__image {
  width: 160px;
}

.icon__spacer {
  width: 20px;
}

.wave__section {
  display: block;
  width: 100%;
  margin-bottom: -1px;
}

.testimony__section {
  display: flex;
  flex-direction: row;
justify-content: space-around;
  padding: 3% 10%;
}

.phrases__text {
  font-size: 2rem;
  color: #009b84;
  /* text-align: center; */
  padding: 1rem;
}

.row__section {
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.nerito__logo__big {
resize: both;  
width: 20%;
}

@media screen and (max-width: 960px) {


  .cover__section {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 80px;
  }
  
  .text__section {
    width: 100%;

    padding: 5%;
  }
  
  .phone__section {
    width: 100%;

    padding: 5%;
    display: flex;
  
    justify-content: center;
  }

  .nerito__logo__big {

    width: 60%;
    }

    .each-fade {
      font-size: 2rem;
    }
}


